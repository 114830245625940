<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="account && Object.keys(account).length !== 0"
      :account="account"
    />
    <b-card>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <div
        v-else-if="!loading"
        class="agencies-table"
      >
        <div class="head-card">
          <div class="head-card-top">
            <h4>{{ $t('page.partners') }}</h4>
            <feather-icon
              v-b-tooltip.hover.top="$t('Close')"
              icon="XIcon"
              size="18"
              class="toggle-close cursor-pointer"
              @click="$router.go(-1)"
            />
          </div>
          <hr>
        </div>
        <div class="custom-search flex-wrap ">
          <div class="total">
            <strong>{{ $t('partners.total') }}: {{ rows.length }}</strong>
          </div>
          <div class="table-filters">
            <div class="d-flex align-items-center">
              <label class="mr-1">{{ $t('form.search') }}</label>
              <b-input-group>
                <b-form-input
                  ref="searchInput"
                  :value="searchTerm"
                  :placeholder="$t('form.search')"
                  type="text"
                  class="d-inline-block"
                  @keyup.enter="searchTerm = $event.target.value"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    @click="searchTerm = $refs.searchInput.vModelValue"
                  >
                    <feather-icon
                      icon="SearchIcon"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div v-if="$route.params.userId">
              <b-button
                v-b-tooltip.hover.top="$t('partners.create')"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="primary"
                class="btn-icon"
                :to="{ name: 'dashboard-user-agency-create'}"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                />
                <span class="d-none d-sm-inline">{{ $t('partners.create') }}</span>
              </b-button>
            </div>
          </div>
        </div>
        <!-- table -->
        <vue-good-table
          v-if="rows.length !== 0"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :select-options="{
            enabled: false,
            selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: false, // disable the select info panel on top
            selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength
          }"
        >
          <!-- Slot: Table Column -->
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span
              v-if="props.column.label ==='Name'"
              class="text-nowrap"
            >
              {{ $t('message.tableHeader.name') }}
            </span>
            <span
              v-else-if="props.column.label ==='Action'"
              class="text-nowrap"
            >
              {{ $t('message.tableHeader.action') }}
            </span>
            <span v-else>
              {{ props.column.label }}
            </span>
          </template>

          <!-- Slot: Table Row -->
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Title -->
            <span
              v-if="props.column.field === 'title'"
              class="text-nowrap"
            >
              <router-link :to="'/users/'+ props.row.owner + '/agencies/'+ props.row._id">
                {{ props.row.title }}
              </router-link>
            </span>

            <!-- Column: Action -->
            <span
              v-else-if="props.column.field === 'action'"
            >
              <span>
                <b-dropdown
                  variant="link"
                  right
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    :to="'/users/'+ props.row.owner + '/agencies/'+ props.row._id"
                  >
                    <feather-icon
                      icon="UsersIcon"
                      class="mr-50"
                    />
                    <span>{{ $t('page.features.users') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    :to="'/users/'+ props.row.owner + '/agencies/'+ props.row._id + '/edit/'"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>{{ $t('form.change') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    variant="danger"
                    @click="deleteEntity('agencies', props.row._id, props.row.title)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>{{ $t('form.delete') }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  {{ $t('message.pagelength') }}
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['5','10','20','40']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{ $t('message.pageText2') }} </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BCard, BSpinner, BPagination, BFormInput, BInputGroup, BInputGroupAppend, BFormSelect, BDropdown, BDropdownItem, BButton, VBTooltip,
} from 'bootstrap-vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import { getUserData } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    Breadcrumbs,
    BSpinner,
    BCard,
    VueGoodTable,
    BPagination,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BButton,
    BDropdown,
    BDropdownItem,
    UserToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      loading: true,
      account: {},
      authUserData: getUserData(),
      breadcrumbItems: [],
      pageLength: 20,
      dir: false,
      columns: [
        {
          label: 'Name',
          field: 'title',
        },
        {
          label: '_id',
          field: '_id',
          hidden: true,
        },
        {
          label: 'Action',
          width: '5%',
          field: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        active       : 'light-success',
        inactive     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    if (this.$route.params.userId !== undefined) {
      this.getUserData()
    } else {
      this.fethAgencies()
    }
  },
  methods: {
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || {}
      this.fethAgencies()
    },
    async fethAgencies() {
      const responseData = this.$route.params.userId !== undefined ? await useJwt.accountAgencies(this.$route.params.userId) : await useJwt.agencies()
      this.rows = responseData.data.agencies || []
      this.setBreadcrumbs()
      this.loading = false
    },
    deleteEntity(entity, id, title) {
      this.$swal({
        title: `${this.$t('swal.deletetitle')} ${title}?`,
        text: this.$t('swal.deletemessage'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('form.cancel'),
        confirmButtonText: this.$t('form.delete'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt.deleteEntity(entity, id).then(deleted => {
            if (deleted.status === 204) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('swal.successfullydeleted'),
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              if (this.$route.params.userId !== undefined) {
                this.getUserData()
              } else {
                this.fethAgencies()
              }
            }
          })
        }
      })
    },
    setBreadcrumbs() {
      if (this.$route.params.userId !== undefined) {
        this.breadcrumbItems = [
          {
            text: this.$t('page.menu.users'),
            to: '/users',
          },
          {
            text: this.account.name,
            to: `/users/${this.$route.params.userId}`,
          },
          {
            text: this.$t('Partners'),
            active: true,
          },
        ]
      } else {
        this.breadcrumbItems = [
          {
            text: this.$t('Partners'),
            active: true,
          },
        ]
      }
    },
  },
}
</script>
<style>
.agencies-table td + td {
  text-align: center!important;
}
</style>
